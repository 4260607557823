import {
  GET_DOCUMENT_FORM,
  GET_DOCUMENT_FORM_SUCCESS,
  GET_DOCUMENT_FORM_FAIL,
  RESET_DOCUMENT_FORM,
  SET_DOCUMENT_FORM,
} from '../constants';

const initialState = {
  loading: false,
  documentForm: {
    subtitles: [],
    name: '',
    mainGroupId: '',
    allowViewOrCopy: false,
    fields: [],
    image: undefined,
    isCopiedItem: false,
  },
  error: '',
};
export default function editDocumentForm(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENT_FORM:
      return {
        ...state,
        loading: true,
      };
    case GET_DOCUMENT_FORM_SUCCESS: {
      const documentForm = action.result.json;

      return {
        ...state,
        documentForm,
        loading: false,
      };
    }
    case GET_DOCUMENT_FORM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_DOCUMENT_FORM:
      return {
        ...state,
        documentForm: action.documentForm,
      };
    case RESET_DOCUMENT_FORM:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
